.disputes-dashboard {
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
  position: relative;
  z-index: 1000;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
  box-sizing: border-box;
}

.disputes-dashboard .dashboard-content {
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
}

.disputes-dashboard h1 {
  text-align: center;
  color: #324d5d;
  margin-bottom: 2rem;
  font-size: 28px;
  font-weight: bold;
}

.dashboard-body {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 2rem;
}

.landing-page {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.landing-container {
  max-width: 1000px;
  width: 100%;
}

.action-cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 20px;
}

.action-card {
  flex-basis: calc(50% - 10px);
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
}

.action-card h2 {
  color: #324d5d;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}

.action-card p {
  margin-bottom: 20px;
  color: #666;
}

.action-card .ui-button {
  margin-top: auto;
  text-decoration: none;
}

.disputes-dashboard .file-upload-section {
  margin-bottom: 2rem;
  background-color: #f9f9f9;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
}

.disputes-dashboard .file-upload-section h2 {
  color: #324d5d;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.disputes-dashboard .file-upload-section h3 {
  color: #324d5d;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e0e0e0;
}

.disputes-dashboard .file-list {
  margin-top: 1rem;
  max-height: 200px;
  overflow-y: auto;
}

.disputes-dashboard .file-item {
  background-color: #f0f0f0;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disputes-dashboard .ui-button,
.disputes-dashboard .remove-file {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-transform: none;
}

.disputes-dashboard .ui-button {
  background-color: #41b29e;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 0.5rem;
  display: inline-block;
}

.disputes-dashboard .ui-button:hover {
  background-color: #324d5d;
}

.disputes-dashboard .remove-file {
  background-color: #e74c3c;
  color: white;
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
}

.disputes-dashboard .remove-file:hover {
  background-color: #c0392b;
}

.disputes-dashboard .remove-all {
  background-color: #e74c3c;
}

.disputes-dashboard .remove-all:hover {
  background-color: #c0392b;
}

.upload-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 1rem;
}

.ui-button.add-more-files {
  background-color: #4CAF50;
  margin-right: auto; 
}

.ui-button.add-more-files:hover {
  background-color: #45a049;
}

.disputes-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  margin-right: 10px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.ui-button.submit-files {
  display: flex;
  align-items: center;
  justify-content: center;
}

.unresolved-disputes {
  padding: 20px;
}

.back-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #f5f5f5;
  color: #324d5d;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transform-origin: center;
  transition: background-color 0.2s ease;
}

.back-button:hover {
  background-color: #e0e0e0;
}

.disputes-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  min-height: 400px;
}

.disputes-header {
  flex: 0 0 auto;
}

.disputes-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.disputes-table-header {
  flex: 0 0 auto;
}

.disputes-table-body {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  scrollbar-width: thin;
  scrollbar-color: lightgray #f5f5f5;
}

.pagination {
  flex: 0 0 auto;
  margin-top: 1rem;
}

.disputes-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.disputes-header h2 {
  color: #324d5d;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.disputes-count {
  color: #666;
  font-size: 16px;
  font-weight: 500;
  background-color: #f9f9f9;
  padding: 8px 16px;
  border-radius: 20px;
}

.error-pill {
  background-color: #fde2e2;
  color: #e63946;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  cursor: help;
  white-space: nowrap;
}

.view-dispute-button {
  background-color: #324d5d;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
  white-space: nowrap;
}

.view-dispute-button:hover {
  background-color: #253b47;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px;
  border-top: 1px solid #e0e0e0;
  background-color: #f9f9f9;
}

.pagination-numbers {
  display: flex;
  align-items: center;
  gap: 8px;
}

.pagination-button {
  padding: 6px 12px;
  background-color: #41b29e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.2s;
}

.pagination-button.active {
  background-color: #324d5d;
  font-weight: 500;
  transform: scale(1.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pagination-button:hover:not(:disabled) {
  background-color: #3a9f8e;
}

.pagination-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination-ellipsis {
  color: #666;
  padding: 0 4px;
}

.disputes-loading-spinner {
  text-align: center;
  padding: 20px;
  color: #666;
}

.error-message {
  color: #e63946;
  text-align: center;
  padding: 20px;
}

.no-disputes-message {
  text-align: center;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
  color: #666;
  font-size: 16px;
  margin-top: 20px;
}

.disputes-table {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}

.disputes-table-header {
  display: grid;
  grid-template-columns: 80px minmax(150px, 250px) 120px 120px 1fr;
  gap: 16px;
  padding: 15px 20px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 600;
  color: #324d5d;
}

.disputes-table-body {
  display: flex;
  flex-direction: column;
}

.dispute-row {
  display: grid;
  grid-template-columns: 80px minmax(150px, 250px) 120px 120px 1fr;
  gap: 16px;
  padding: 15px 20px;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.2s;
}

.dispute-row:last-child {
  border-bottom: none;
}

.dispute-row:hover {
  background-color: #f9f9f9;
}

.col-id {
  color: #324d5d;
}

.col-name {
  color: #324d5d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.col-status {
  display: flex;
  align-items: center;
}

.col-due-date {
  color: #324d5d;
}

.col-actions {
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.next-dispute-button {
  white-space: nowrap;
}

.disputes-table-body.loading {
  opacity: 0.6;
  pointer-events: none;
}

.pagination-ellipsis {
  padding: 8px 12px;
  color: #666;
}

.header-left {
  display: flex;
  gap: 16px;
  align-items: center;
}

.back-button, 
.next-dispute-button {
  height: 36px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.dispute-document,
.pdf-container {
  margin-top: 24px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.document-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.document-controls {
  display: flex;
  align-items: center;
  gap: 12px;
}

.control-button {
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  color: #324d5d;
  cursor: pointer;
  transition: background-color 0.2s;
}

.control-button:hover:not(:disabled) {
  background: #e0e0e0;
}

.control-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.zoom-level {
  font-size: 14px;
  color: #666;
  min-width: 48px;
  text-align: center;
}

.pdf-viewer {
  width: 100%;
  height: 800px;
  border: none;
}

.edit-icon {
  color: #1976d2;
  font-size: 12px;
  opacity: 0;
}

.editable-value:hover .edit-icon {
  opacity: 1;
}

.editable-field input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #1976d2;
  border-radius: 4px;
  font-size: inherit;
  color: inherit;
  background-color: white;
  min-height: 36px;
}

.editable-field input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.editable-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  min-height: 36px;
}

.editable-value:hover {
  border-color: #1976d2;
  background-color: #fff;
}

.detail-item > span {
  padding: 8px 12px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  min-height: 36px;
}

.detail-item label,
.form-field label {
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
  text-align: left;
}

.dispute-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
}

.header-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dispute-meta {
  display: flex;
  gap: 24px;
  color: #666;
  background-color: #f5f5f5;
  padding: 8px 16px;
  border-radius: 4px;
  margin-top: 8px;
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
}

.meta-item label {
  font-weight: 600;
  color: #324d5d;
}

.meta-item span {
  color: #666;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.mode-indicator {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.mode-indicator.edit {
  background-color: #e3f2fd;
  color: #1976d2;
}

.mode-indicator.view {
  background-color: #f5f5f5;
  color: #666;
}

.save-button {
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.save-button:hover:not(:disabled) {
  background-color: #43a047;
}

.save-button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.detail-grid {
  display: grid;
  gap: 16px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.detail-item input,
.detail-item select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  text-align: left;
}

.editable-field {
  position: relative;
  width: 100%;
}

.editable-field input.editable:hover {
  border-color: #1976d2;
}

.editable-field input.editable:focus {
  outline: none;
  border-color: #1976d2;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.editable-field input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.detail-item > span {
  padding: 8px 12px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  min-height: 36px;
  display: flex;
  align-items: center;
}

.dispute-content {
  display: flex;
  gap: 24px;
}

.dispute-left-panel {
  flex: 0.4;
  min-width: 0;
}

.dispute-right-panel {
  flex: 0.6;
  min-width: 0;
}

.dispute-form-section {
  display: flex;
  flex-direction: column;
}

.tab-content {
  flex: 1;
  min-height: 0;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 4px 4px;
}

.tabs {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 0;
}

.tab-list {
  display: flex;
  gap: 8px;
  margin-bottom: -1px;
}

.tab {
  background: none;
  border: 1px solid transparent;
  border-bottom: none;
  color: #666;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.tab:hover {
  color: #324d5d;
}

.tab.selected {
  color: #324d5d;
  background: #fff;
  border-color: #e0e0e0;
  border-bottom: 1px solid #fff;
  margin-bottom: -1px;
}

.dispute-sections {
  margin-bottom: 24px;
}

.resolve-section {
  padding: 8px 0;
}

.search-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-sections {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.detail-section {
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 12px 24px;
}

.detail-section h4 {
  color: #324d5d;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 12px 0;
}

.detail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 12px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.detail-item label {
  font-size: 12px;
  color: #666;
}

.detail-item input,
.detail-item select {
  padding: 6px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
  background-color: white;
}

.detail-item input:disabled,
.detail-item select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.form-actions {
  display: flex;
  justify-content: flex-start;
  padding: 0 8px;
}

.primary-button {
  padding: 8px 24px;
  background-color: #324d5d;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.primary-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.search-results {
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #324d5d #f5f5f5;
}

.search-results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: white;
}

.search-results table {
  width: 100%;
  border-collapse: collapse;
}

.search-results th {
  text-align: left;
  padding: 8px 16px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  font-size: 13px;
  font-weight: 500;
  color: #324d5d;
}

.search-results td {
  padding: 8px 16px;
  border-bottom: 1px solid #eee;
  font-size: 13px;
  color: #333;
  vertical-align: middle;
}

.pagination {
  position: sticky;
  bottom: 0;
  background-color: #f9f9f9;
  z-index: 1;
}

.search-confidence {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #e0e0e0;
}

.search-confidence.definitive {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.search-confidence.exact {
  background-color: #e3f2fd;
  color: #1976d2;
}

.search-confidence.broad {
  background-color: #fff3e0;
  color: #f57c00;
}

.search-confidence.none {
  background-color: #ffebee;
  color: #c62828;
}

.search-results table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.search-results th,
.search-results td {
  text-align: left;
  border-bottom: 1px solid #eee;
}

.search-results th:nth-child(1),
.search-results td:nth-child(1) {
  width: 40%;
}

.search-results th:nth-child(2),
.search-results td:nth-child(2) {
  width: 45%;
}

.search-results th:nth-child(3),
.search-results td:nth-child(3) {
  width: 15%;
}

.user-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  line-height: 1.3;
}

.user-name {
  font-weight: 500;
  color: #324d5d;
}

.user-contact {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 12px;
  color: #666;
  margin-top: 2px;
}

.user-contact span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666;
  font-size: 13px;
}

.user-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.expand-details-button,
.select-user-button {
  width: 28px;
  height: 28px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.expand-details-button {
  background-color: #1976d2;
  color: white;
}

.select-user-button {
  background-color: #4CAF50;
  color: white;
}

.select-user-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.expand-details-button i {
  font-size: 14px;
}

.select-user-button i {
  font-size: 12px;
}

.dispute-form-section button {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.dispute-form-section .back-button {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #666;
}

.dispute-form-section .back-button:hover {
  background-color: #e0e0e0;
}

.dispute-form-section .clear-button {
  background-color: #fff;
  border: 1px solid #ff4444;
  color: #ff4444;
  margin-right: 16px;
}

.dispute-form-section .clear-button:hover {
  background-color: #ff4444;
  color: white;
}

.dispute-form-section .save-button {
  background-color: #4CAF50;
  border: none;
  color: white;
}

.dispute-form-section .save-button:hover {
  background-color: #388e3c;
}

.dispute-form-section .save-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.header-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
}

.header-button--secondary {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #666;
}

.header-button--secondary:hover {
  background-color: #e0e0e0;
}

.header-button--primary {
  background-color: #41b29e;
  border: none;
  color: white;
}

.header-button--primary:hover {
  background-color: #399e8b;
}

.header-button i {
  font-size: 12px;
}

.detail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.detail-item {
  display: flex;
  flex-direction: column;
}

.detail-item input,
.detail-item select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

.detail-item input:disabled,
.detail-item select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.detail-item .invalid-input {
  border-color: #ff4444;
}

.detail-item select {
  cursor: pointer;
}

.dispute-section {
  background-color: white;
  padding: 12px 24px;
  border-radius: 4px;
}

.selected-user-section {
  padding: 20px;
  background: white;
  border-radius: 4px;
}

.selected-user-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.selected-user-header h3 {
  margin: 0;
  color: #324d5d;
  font-size: 16px;
}

.selected-user-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.detail-row {
  display: flex;
  gap: 12px;
}

.detail-label {
  min-width: 100px;
  font-weight: 500;
  color: #666;
}

.details-summary {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 24px;
}

.details-summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.details-summary-header h3 {
  margin: 0;
  font-size: 14px;
  color: #324d5d;
}

.edit-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #666;
  font-size: 12px;
  cursor: pointer;
}

.edit-button i {
  font-size: 12px;
}

.details-summary-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.summary-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.summary-label {
  font-size: 12px;
  color: #666;
}

.resolution-form {
  margin-top: 24px;
}

.resolution-form h3 {
  margin: 0 0 16px 0;
  font-size: 14px;
  color: #324d5d;
}

.resolve-button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.resolve-button:hover:not(:disabled) {
  background-color: #388e3c;
}

.resolve-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.search-results.loading {
  opacity: 0.6;
  pointer-events: none;
}

.expanded-user-details {
  padding: 20px;
  background: white;
}

.expanded-user-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.back-to-results-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #666;
  cursor: pointer;
  font-size: 14px;
}

.back-to-results-button i {
  font-size: 12px;
}

.user-detail-sections {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.detail-section {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 16px;
}

.detail-section h4 {
  color: #324d5d;
  font-size: 14px;
  margin: 0 0 16px 0;
}

.detail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.user-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.expand-details-button {
  padding: 6px 12px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #666;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.expand-details-button:hover {
  background-color: #e0e0e0;
}

.form-field select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  width: 100%;
  height: 35px;
  cursor: pointer;
}

.form-field select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.select-user-button {
  width: 28px;
  height: 28px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #4CAF50;
  border: none;
  color: white;
  cursor: pointer;
}

.select-user-button-large {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background-color: #4CAF50;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.select-user-button-large:hover:not(:disabled) {
  background-color: #388e3c;
}

.select-user-button-large:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.select-user-button-large i {
  font-size: 14px;
}

.search-results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.confidence-pill {
  font-size: 12px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 12px;
  text-transform: capitalize;
}

.confidence-pill.definitive {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.confidence-pill.exact {
  background-color: #e3f2fd;
  color: #1976d2;
}

.confidence-pill.broad {
  background-color: #fff3e0;
  color: #f57c00;
}

.confidence-pill.none {
  background-color: #ffebee;
  color: #c62828;
}

.search-results-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.search-results-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
}
.search-results {
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-height: 1000px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #324d5d #f5f5f5;
}

.search-results table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  background-color: white;
}

.search-results thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.search-results th {
  padding: 12px 16px;
  font-weight: 500;
  color: #324d5d;
  text-align: left;
  border-bottom: 2px solid #e0e0e0;
}

.detail-section {
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 12px 24px;
}

.detail-section h4 {
  color: #324d5d;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 12px 0;
}

.detail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 12px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.detail-item label {
  font-size: 12px;
  color: #666;
}

.detail-item span {
  padding: 6px 8px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
  min-height: 18px;
}

.search-results .form-sections {
  background-color: white;
  padding: 12px 24px;
}

.clear-button,
.save-button {
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: white;
}

.clear-button {
  border: 1px solid #ddd;
  color: #666;
}

.save-button {
  background-color: #324d5d;
  border: none;
  color: white;
}

.save-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.search-results .form-actions {
  padding: 16px 24px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.edit-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  color: #666;
  font-size: 13px;
  cursor: pointer;
}

.edit-button i {
  font-size: 12px;
}

.search-results-loading,
.search-results-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  text-align: center;
  color: #666;
}

.search-results-loading .disputes-spinner {
  margin-bottom: 16px;
}

.search-results-loading p {
  font-size: 14px;
  margin: 0;
}

.search-results-empty i {
  font-size: 24px;
  margin-bottom: 12px;
  color: #999;
}

.search-results-empty p {
  font-size: 14px;
  margin: 0;
}

.search-results-empty .hint {
  font-size: 13px;
  color: #999;
  margin-top: 8px;
}

.user-info .user-name {
  font-size: 13px;
  font-weight: 500;
}

.user-info .user-contact {
  font-size: 12px;
  color: #666;
}

.user-address {
  font-size: 13px;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

.expand-details-button,
.select-user-button,
.back-to-results-button,
.select-user-button-large,
.primary-button,
.clear-button,
.save-button {
  cursor: pointer;
}

.expand-details-button:disabled,
.select-user-button:disabled,
.back-to-results-button:disabled,
.select-user-button-large:disabled,
.primary-button:disabled,
.clear-button:disabled,
.save-button:disabled {
  cursor: not-allowed;
}

.tab.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* For Webkit browsers (Chrome, Safari, etc.) */
.disputes-table-body::-webkit-scrollbar {
  width: 8px;
}

.disputes-table-body::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 4px;
}

.disputes-table-body::-webkit-scrollbar-thumb {
  background-color: #324d5d;
  border-radius: 4px;
  border: 2px solid #f5f5f5;
}

.disputes-table-body::-webkit-scrollbar-thumb:hover {
  background-color: #253b47;
}

.loan-section h5 {
  color: #324d5d;
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 8px 0;
}

.loans-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.loan-item {
  background-color: #e3f2fd;
  color: #1976d2;
  padding: 4px 8px;
  margin: 4px;
  border-radius: 4px;
  font-size: 12px;
  display: inline-flex;
}

.loan-item.empty {
  background-color: #f5f5f5;
  color: #666;
  font-style: italic;
}