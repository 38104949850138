.login-page {
  font-family: "Montserrat", sans-serif;
}

.login-page-header {
  font-size: 60px;
  color: white;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
  width: 100%;
}

.login-form {
  border: 1px solid black;
  border-radius: 4px;
  background-color: ivory;
  text-align: center;
  margin: auto;
  padding: 30px;
  width: 30%;
}

.title {
  font-size: 40px;
}

.subtitle {
  font-size: 15px;
  color: grey;
}

.login-form input {
  margin: 6px;
  font-size: 14px;
  padding: 4px;
  width: 80%;
  height: 25px;
  border: 0.5px solid #324d5d;
  border-radius: 4px;
}

.login-form button {
  width: 65%;
  font-family: "Proxima Nova Light";
  padding: 10px 25px;
  margin-top: 20px;
  border-radius: 10px;
  font-size: 24px;
  border: 1px solid #3fb39d;
  background-color: #3fb39d;
  color: #fff;
}

.login-form a {
  color: #324d5d;
  font-size: 0.75rem;
  text-decoration: underline;
  justify-self: end;
}

.login-form a:hover {
  color: #5289a8;
  cursor: pointer;
}

.submit-button:hover {
  cursor: pointer;
  border: 1px solid #22cdae;
  background-color: #22cdae;
  color: ivory;
}

.submit-button:active {
  background-color: #324d5d;
}

.login-pending-container {
  height: 200px;
}

.loader {
  height: 50%;
  position: relative;
}

.loading-title {
  font-size: 25px;
}
