.hierarchical-dropdown {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.dropdown-trigger {
  min-width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-menu {
  position: absolute;
  bottom: 100%;
  left: 0;
  min-width: 250px;
  max-height: 400px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;
  z-index: 1000;
}

.dropdown-menu::-webkit-scrollbar {
  width: 8px;
}

.dropdown-menu::-webkit-scrollbar-track {
  background: transparent;
}

.menu-up {
  bottom: 100%;
  top: auto;
}

.dropdown-category {
  position: relative;
}

.dropdown-category-header {
  padding: 8px 12px;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s;
}

.dropdown-category-header:hover {
  background-color: #f5f5f5;
}

.dropdown-submenu {
  min-width: 250px;
  max-width: 350px;
  max-height: 400px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.15s ease-in-out;
  margin-left: -5px;
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;
  z-index: 1001;
}

.dropdown-submenu::-webkit-scrollbar {
  width: 8px;
}

.dropdown-submenu::-webkit-scrollbar-track {
  background: transparent;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} 