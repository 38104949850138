
.timeout-warning {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.timeout-warning-content {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.timeout-warning h3 {
  color: #324d5d;
  margin: 16px 0;
}

.timeout-warning p {
  color: #666;
  margin-bottom: 20px;
}

.warning-icon {
  color: #f57c00;
  font-size: 24px;
}

.extend-button {
  background-color: #41b29e;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.extend-button:hover {
  background-color: #399e8b;
}