html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html,
body {
  width: 100vw;
}

input:focus {
  outline-color: grey;
}

button:hover {
  cursor: pointer;
  background-color: lightgrey;
}
button:active {
  background-color: #3fb39d;
  color: white;
}
