.flash-message-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  pointer-events: none;
}

.flash-message {
  padding: 15px 25px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%); 
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, opacity 0.3s ease; 
  opacity: 0.95;
  color: white;
  pointer-events: auto; 
  min-width: 300px;
  max-width: 600px;
}

.flash-message.success {
  background-color: #2a9d8f;
}

.flash-message.warning {
  background-color: #e9c46a;
}

.flash-message.error {
  background-color: #e63946;
}

.flash-message button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}