#loan-info h3 {
  font-weight: bold;
  color: #3fb39d;
}

#loan-info li {
  list-style: none;
  margin: 10px 0px 10px 0px;
}

.statement-link {
  font-size: 10px;
  word-break: break-all;
}

.paid {
  color: #3fb39d;
}

.unpaid {
  color: #e60000;
}

.invalid {
  color: grey;
}

.denial {
  color: red;
}

.CreditBuilder {
  color: orange;
}

.CommunityLoan {
  color: purple;
}

.underwriting {
  color: purple;
}

.loan-status {
  color: blue;
}

.approved {
  color: #3fb39d;
}

button:hover {
  cursor: pointer;
}

.statementButton {
  color: purple;
}

.statementButton:hover {
  cursor: pointer;
  text-decoration: underline;
}
