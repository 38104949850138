.question-mark,
.modal-close {
  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  vertical-align: center;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 10;
}

.modal-box {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  height: auto;
  max-height: 90%;
  overflow-y: scroll;
  margin: auto;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 6px;
  transform: translate(-50%, -50%);
  z-index: 11;
}

.modal-content {
  margin: 20px;
  font-size: inherit;
  color: black;
}
.modal-content p {
  padding: 10px 0px !important;
  margin-left: 0px !important;
}

.modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100;
}

.profile #progress_list li .circle.selected {
  background-color: white !important;
  color: #e47a36;
}

.button-word {
  text-decoration: underline;
}

.button-word:hover {
  cursor: pointer;
}

.button-word:active {
  color: #e47a36;
}

.editable:hover,
.editable-dashboard:hover,
.editable-user-info:hover {
  cursor: pointer;
  color: #e47a36;
}

.new-comment-orange {
  color: #e47a36;
  text-decoration: underline;
}

/* Medium screens (768px) */
@media screen and (min-width: 768px) {
  .standard_modal {
    width: 65%;
  }
  .wide_modal {
    width: 90%;
  }
  .modal_max_height {
    height: 90%;
  }
  .second_level_fixed {
    z-index: 20;
    width: 60%;
    height: 60%;
  }
  .second-modal-background {
    z-index: 20;
  }
}
