.account-history {
  font-family: Arial, sans-serif;
}

.history-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.history-grid {
  border: 1px solid #ccc;
}

.header-row, .history-row {
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  gap: 1px;
  background-color: #f0f0f0;
}

.header-cell, .history-cell {
  padding: 0.5rem;
  text-align: center;
  background-color: #fff;
}

.header-cell {
  font-weight: bold;
}

.year-cell {
  font-weight: bold;
  text-align: left;
}

.disabled-cell {
  background-color: #f0f0f0;
  color: #999;
}

.fcra-info li::before,
.fcra-info li span[data-label]::before {
  content: attr(data-label);
  color: blue;
  margin-right: 0.5em;
}

.fcra-info li {
  list-style-type: none;
}

.fcra-info .copyable {
  cursor: pointer;
  padding: 2px 4px;
  border-radius: 2px;
}

.fcra-info .copyable:hover {
  background-color: #f0f0f0;
}

.fcra-indent-1 {
  margin-left: 10px;
}