.toggle-container-user {
  margin-top: -12px;
  margin-bottom: -14px;
  display: flex;
}

.toggle-div-user {
  margin-right: 20px;
}

.toggles-div {
  margin-bottom: -6px;
  display: flex;
  justify-content: start;
}

.toggle-li {
  display: inline-block;
  overflow: hidden;
  margin-top: -4px;
}

.toggle-li-user {
  margin-right: 20px;
}

.toggle-name {
  padding-right: 6px;
}

.toggle-checkbox {
  height: 0;
  width: 0;
  display: none;
}

.toggle-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 35px;
  height: 20px;
  background: grey;
  border-radius: 40px;
  position: relative;
}

.toggle-label .toggle-button {
  content: '';
  display: flex;
  position: absolute;
  left: 1px;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.toggle-checkbox:checked + .toggle-label .toggle-button {
  left: calc(100%);
  transform: translateX(-100%);
}

.toggle-checkbox:checked + .toggle-label {
  background: red;
}
