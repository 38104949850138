.user-show {
  background-color: white;
  margin: 20px;
  padding: 10px;
  border-radius: 6px;
  min-height: 70vh;
  height: auto;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: left;
}

.comments-box {
  max-height: 100px;
  overflow-y: scroll;
  border: 1px solid #3fb39d;
  border-radius: 4px;
  padding: 6px;
  margin: 4px 0px;
}

.loans {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.user-show #loan-info {
  max-height: none;
  border-left: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 0 20px 0 20px;
  width: 400px;
}

.user-show #user-info {
  margin-right: 20px;
  height: contents;
  max-height: none;
}

.user-actions {
  display: block;
  margin-top: 10px;
}

.dnp {
  color: red;
}

.recent-messages {
  max-height: 200px;
  max-width: 500px;
  overflow-y: scroll;
}

.inbound {
  color: #324d5d;
}

.outbound {
  color: #3fb39d;
}

.subject {
  color: #c6361f;
}

.survey-show {
  background-color: white;
  margin: 20px;
  padding: 10px;
  border-radius: 6px;
  min-height: 70vh;
  height: auto;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: space-between;
}

.survey-list {
  background-color: white;
  margin: 20px;
  padding: 10px;
  border-radius: 6px;
  min-height: 70vh;
  height: auto;
  font-family: "Montserrat", sans-serif;
}

.survey-show .user-display {
  height: 70vh;
  width: 200px;
  overflow-y: scroll;
  border: 1px solid lightgrey;
  align-items: center;
  text-align: center;
}

.survey-show .user-display li:hover {
  background-color: #3fb39d;
  color: #fff;
}
.survey-title {
  font-size: 24px;
  color: #3fb39d;
}
.survey-question {
  display: block;
  clear: both;
  margin-top: 20px;
}

.survey-prompt {
  font-weight: bold;
  font-size: 20px;
}

.survey-response {
  display: block;
}
