.error-container {
  background-color: #e37262;
  width: 75%;
  border-radius: 10px;
  padding: 0.5rem;
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
  text-align: center;
}

.error-message {
  font-size: 1rem;
  color: white;
  margin-bottom: 1rem;
}

.error-close {
  color: white;
  width: 100%;
  text-align: right;
  margin: 0;
  padding: 0;
}
.error-close:hover {
  cursor: pointer;
  color: #f39588;
}