.tip {
  position: relative;
  display: inline-block;
}

.tip .tiptext {
  visibility: hidden;
  width: auto;
  background-color: white;
  color: #324d5d;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  border: 1px solid #324d5d;
}

.tip:hover .tiptext {
  visibility: visible;
  opacity: 1;
}

.tip .income-tiptext {
  right: 105%;
}

